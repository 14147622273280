import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import ReactLoader from './components/Loader';
import * as ROUTES from './constants/routes';
import { AuthContextProvider } from './helpers/AuthContext';
import { Login } from './pages/Login';
import { GuardedRoutes } from './utils/GuardedRoutes';
import { PublicRoutes } from './utils/PublicRoutes';

const Home = lazy(() =>
    import('./pages/Home').then((module) => ({ default: module.Home }))
);

const theme = createMuiTheme({
    typography: {
        "fontFamily": `"Poppins"`,
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500
    },
    palette: {
        primary: {
            main: '#3B82F6'
        },
        secondary: {
            main: '#1E3A8A'
        }
    }
});

function App() {
    return (
        <MuiThemeProvider theme={theme}>
            <AuthContextProvider>
                <Router>
                    <Suspense fallback={<ReactLoader />}>
                        <Switch>
                            <PublicRoutes path={ROUTES.Login} component={Login} />
                            <GuardedRoutes path={ROUTES.Home} component={Home} />
                        </Switch>
                    </Suspense>
                </Router>
            </AuthContextProvider>
        </MuiThemeProvider>
    );
}

export default App;
