import jwtDecode, { JwtPayload } from 'jwt-decode';
import { useState } from "react";
import Auth from './../services/Auth';

interface LoginData {
    emailAddress: string, password: string
}

export interface IUseAuthProvider {
    logIn: (data: LoginData) => Promise<{ error: boolean, message: string }>;
    logOut: () => void;
    isAuthenticated: () => void;
    user: any;
}

export const useAuthProvider = (): IUseAuthProvider => {
    const [user, setUser] = useState(null);

    const logIn = async (data: LoginData) => {
        console.log('Authenticating user')
        let result = (await Auth.login(data)).data;
        console.log(result)
        if (result.statusCode === 200 || result.statusCode === 201) {
            setUser(result.data);
            localStorage.setItem('user', JSON.stringify(result.data));
            return { error: false, message: '' };
        }
        return { error: true, message: result.message };
    }

    const logOut = () => {
        let loggedInUser = localStorage.getItem('user');
        if (loggedInUser) {
            setUser(null);
            localStorage.removeItem('user');
        }
    }

    const isAuthenticated = () => {
        let loggedInUser = localStorage.getItem('user');
        if (loggedInUser) {
            let token = JSON.parse(loggedInUser).token;
            if (jwtDecode<JwtPayload>(token).exp as number > Date.now() / 1000) {
                setUser(JSON.parse(loggedInUser));
            } else {
                localStorage.removeItem('user');
            }
        }
    }

    return { user, logIn, logOut, isAuthenticated };
}
