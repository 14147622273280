import Loader from 'react-loader-spinner';
import styled from 'styled-components';

export default function ReactLoader() {
    return (
        <FallBackContainer>
            <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
        </FallBackContainer>
    );
}
const FallBackContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
