import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAuth } from '../helpers/UseAuth';

interface Props extends RouteProps { }

export const PublicRoutes: React.FC<Props> = ({ ...props }) => {
    const auth = useAuth();
    auth?.isAuthenticated();
    return auth?.user
        ? (<Redirect to="/" />)
        : (<Route {...props} />)
};

