import axios from "axios";

class Auth {

    BASE_ENDPOINT = process.env.REACT_APP_BASE_ENDPOINT;

    async login(loginData: any) {
        return await axios.post(`${this.BASE_ENDPOINT}/auth/login`, loginData);
    }

    async register(registerData: any) {
        const headers = { Authorization: `Bearer ${JSON.parse(localStorage.getItem('user') as string)?.token}` }
        return await axios.post(`${this.BASE_ENDPOINT}/auth/register`, registerData, { headers: headers });
    }

    async getUsers() {
        const headers = { Authorization: `Bearer ${JSON.parse(localStorage.getItem('user') as string)?.token}` }
        return await axios.get(`${this.BASE_ENDPOINT}/auth/users`, { headers: headers });
    }
}

export default new Auth();