
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAuth } from '../helpers/UseAuth';

interface Props extends RouteProps { }

export const GuardedRoutes: React.FC<Props> = ({ ...rest }) => {
    const auth = useAuth();
    console.log(auth?.user);
    return auth?.user
        ? (<Route {...rest} />)
        : (<Redirect to="/login" />)
}

